import ReactPlayer from 'react-player/lazy'
import { useRenderOnMount } from '@kaliber/use-render-on-mount'
import { PlayButtonSmall } from  '/features/buildingBlocks/PlayButtonAndTitle'
import { ImageCover } from '/features/buildingBlocks/Image'
import { useSpring, animated } from 'react-spring'
import { useTranslate } from '/machinery/I18n'
import { Icon } from '/features/buildingBlocks/Icon'
import { getVideoMetaDataProps, trackVideoMetadata } from '/analytics/video'

import expandIcon from '/images/icons/expand-content.raw.svg'

import styles from './InlineVideo.css'

const playerConfig = {
  vimeo: {
    playerOptions: {
      title: false
    },
  },
}

export function InlineVideo({ url, poster, playing, title = undefined, onVideoChange }) {
  const isMounted = useRenderOnMount()
  const videoRef = React.useRef(null)
  const [isFullscreen, setIsFullscreen] = React.useState(false)
  const spring = useSpring({ opacity: playing ? 0 : 1 })

  return (
    <div className={styles.component}>
      {!playing && (
        <>
          <PlayButtonSmall
            layoutClassName={styles.playButtonLayout}
            onClick={handlePlay}
            {...{ playing }}
          />
          <FullScreenButton
            layoutClassName={styles.fullScreenButtonLayout}
            onClick={handleFullScreen}
          />
          {poster && (
            <animated.div className={styles.image} style={spring}>
              <ImageCover image={poster} aspectRatio={3 / 4} />
            </animated.div>
          )}
        </>
      )}

      {isMounted && (
        <div className={cx(styles.videoContainer, !playing && !isFullscreen && styles.hidden)}>
          <ReactPlayer
            width="100%"
            height="100%"
            controls
            onPause={handlePause}
            ref={videoRef}
            playsinline
            autoPlay
            config={playerConfig}
            // eslint-disable-next-line @kaliber/layout-class-name
            className={styles.player}
            {...{ url, playing }}
          />
        </div>
      )}
    </div>
  )

  function handleFullScreen() {
    if (videoRef?.current) {
      const iframe = videoRef.current.getInternalPlayer()
      if (iframe?.requestFullscreen) {
        iframe.requestFullscreen()
        setIsFullscreen(true)
      } else {
        console.warn('Fullscreen API is niet ondersteund in deze browser of video.')
      }
    }

    document.addEventListener('fullscreenchange', () => {
      if (document.fullscreenElement) {
        setIsFullscreen(true)
      } else {
        setIsFullscreen(false)
      }
    })
  }

  function handlePlay() {
    onVideoChange()
    if (videoRef?.current) {
      const internalPlayer = videoRef.current.getInternalPlayer()
      internalPlayer.focus()
    }
    handleVideoTracking('video started')
  }

  function handlePause() {
    handleVideoTracking('video paused')
  }

  function handleVideoTracking(action) {
    const duration = videoRef.current?.getDuration()
    const currentTime = videoRef.current?.getCurrentTime()
    const progress = currentTime / duration * 100

    trackVideoMetadata({
      category: 'shared-video',
      type: 'play',
      action,
      video: getVideoMetaDataProps({ title, duration, progress })
    })
  }
}

function FullScreenButton({ layoutClassName, onClick }) {
  const { __ } = useTranslate()

  return (
    <button
      aria-label={__`full-screen-label`}
      className={cx(styles.componentFullScreenButton, layoutClassName)}
      {...{ onClick }}
    >
      <Icon icon={expandIcon} />
    </button>
  )
}
