import { determineLayoutByActiveVideo } from '/features/buildingBlocks/Homepage/determineLayoutByActiveVideo'
import { useTopOfPage } from '/machinery/useTopOfPage'
import { Tile } from '/features/buildingBlocks/tile/Tile'
import { InlineVideo } from '/features/buildingBlocks/Homepage/InlineVideo'
import { useTranslate } from '/machinery/I18n'
import { Icon } from '/features/buildingBlocks/Icon'
import { useFocusWithin } from '/machinery/useFocusWithin'

import crossIcon from '/images/icons/cross.raw.svg'

import styles from './GridHome.css'

export const GridHome = React.forwardRef(GridHomeImpl)
export function GridHomeImpl({ imageTiles, videoTiles, isBottomVideoActive, isTopVideoActive, layoutClassName, onSetVideoActiveChange }, ref) {
  const [isFocus, setIsFocus] = React.useState(false)
  const topOfPage = useTopOfPage()

  const isStacked = (topOfPage && !isFocus)
  const isVideoActive = (isTopVideoActive || isBottomVideoActive)

  const { onFocus, onBlur } = useFocusWithin({
    isEnabled: isFocus,
    onBlurChange: () => setIsFocus(false),
    onFocusChange: () => setIsFocus(true)
  })

  return (
    <div className={cx(styles.componentImpl, layoutClassName, isVideoActive && styles.noClickArea)}>
      <div {...{ onFocus, onBlur }} className={cx(styles.grid, isBottomVideoActive && styles.isBottomVideoActive, isTopVideoActive && styles.isTopVideoActive)}>
        {videoTiles[0] && (
          <VideoTile
            id={1}
            ref={ref[0]}
            layoutClassName={styles.tile1Layout}
            tile={videoTiles[0]}
            activeVideo={isTopVideoActive}
            hidden={isBottomVideoActive}
            onVideoChange={() => determineLayoutByActiveVideo({ activeVideo: 'top', onChange: onSetVideoActiveChange })}
            {...{ isStacked, isBottomVideoActive }}
          />
        )}
        {imageTiles.map((x, i) => x && (
          <ImageTile
            key={i}
            id={i + 2}
            layoutClassName={cx(styles[`tile${i + 2}Layout`])}
            tile={x}
            {...{ isStacked, isVideoActive }}
          />
        ))}
        {videoTiles[1] && (
          <VideoTile
            id={5}
            tile={videoTiles[1]}
            activeVideo={isBottomVideoActive}
            hidden={isTopVideoActive}
            onVideoChange={() => determineLayoutByActiveVideo({ activeVideo: 'bottom', onChange: onSetVideoActiveChange })}
            layoutClassName={styles.tile5Layout}
            ref={ref[1]}
            {...{ isStacked, isBottomVideoActive }}
          />
        )}
      </div>
    </div>
  )
}

function ImageTile({ layoutClassName, tile, id, isStacked, isVideoActive }) {
  return (
    <Base
      className={cx(styles.componentImageTile, isVideoActive && styles.hidden)}
      {...{ layoutClassName, isStacked, id }}
    >
      <Tile
        availableStyleContexts={['dark', 'blue-dark', 'green']}
        blankTile={isStacked && (tile._type !== 'tileStatistic' && tile._type !== 'tileQuote')}
        {...{ tile }}
      />
    </Base>
  )
}

export const VideoTile = React.forwardRef(VideoTileImpl)
function VideoTileImpl({ tile, isStacked, hidden, layoutClassName, activeVideo, onVideoChange, id, isBottomVideoActive }, videoRef) {
  const hasScrolledRef = React.useRef(false)

  useScrollIntoViewOnPlay({ videoPlaying: activeVideo, videoRef, hasScrolledRef })
  useResetHasScrolledOnPause({ videoPlaying: activeVideo, hasScrolledRef })

  return (
    <div
      {...{ id }}
      data-style-context='dark'
      className={cx(
        styles.componentVideoTileImpl,
        !isStacked && styles.compact,
        hidden && styles.hidden,
        activeVideo && styles.compact,
        isBottomVideoActive && styles.flat,
        layoutClassName
      )}
    >
      {activeVideo && (
        <CloseButton
          layoutClassName={styles.buttonLayout}
          onClick={onVideoChange}
        />
      )}

      <Base
        ref={videoRef}
        className={styles.videoTile}
        {...{ isStacked }}
      >
        <div className={styles.videoInner}>
          <InlineVideo
            url={tile.src}
            poster={tile.poster}
            playing={activeVideo}
            {...{ onVideoChange }}
          />
        </div>
      </Base>
    </div>
  )
}

export const Base = React.forwardRef(BaseImpl)
function BaseImpl({ children, isStacked, className, id = undefined, layoutClassName = undefined }, ref) {
  return (
    <div
      {...{ ref, id }}
      className={cx(styles.componentBaseImpl, className, layoutClassName, !isStacked && styles.compact)}
    >
      <div className={styles.children}>{children}</div>
    </div>
  )
}

function CloseButton({ onClick, layoutClassName }) {
  const { __ } = useTranslate()

  return (
    <button
      aria-label={__`close-video-state`}
      type='button'
      className={cx(styles.componentCloseButton, layoutClassName)}
      {...{ onClick }}
    >
      <Icon icon={crossIcon} />
    </button>
  )
}

function useScrollIntoViewOnPlay({ videoPlaying, videoRef, hasScrolledRef }) {
  React.useEffect(() => {
    if (videoPlaying && !hasScrolledRef.current) {
      videoRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
      hasScrolledRef.current = true
    }
  }, [videoPlaying, videoRef, hasScrolledRef])
}

function useResetHasScrolledOnPause({ videoPlaying, hasScrolledRef }) {
  React.useEffect(() => {
    if (!videoPlaying) {
      hasScrolledRef.current = false
    }
  }, [videoPlaying, hasScrolledRef])
}
